.background {
  background-color: #F4F4F4;
  padding: 2rem;
}

.footer {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.footer a {
  text-decoration: none;
  color: #000000;
}

.footer__img {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.footer__img img {
  height: 4rem;
}

.footer__img a:not(:last-of-type) {
  margin-right: 2rem;
}

@media screen and (max-width: 850px) {
  .footer {
    width: 85%;
  }
  .footer__img a:not(:last-of-type) {
    margin-right: 1rem;
  }
}


@media screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
  }
  .footer__img {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .footer__img a {
    margin-bottom: 2rem;
  }

  /* .footer__img a:not(:last-of-type) {
    margin: 0;
  } */
}


@media screen and (max-width: 600px) {
  .footer {
    width: 95%;
  }
}

@media screen and (max-width: 540px) {
  .footer {
    width: 99%;
  }
}

/* @media screen and (max-width: 300px) {
  .footer__img a:not(:last-of-type) {
    margin-right: 2rem;
  }
} */