/* --------------------------Order Gift ----------------------- */
.wrapper {
  width: 55%;
  margin: 0 auto;
  background-color: #FFF;
  border-radius: 1.6rem;
  box-shadow: 0px 2px 20px rgba(48, 48, 48, 0.05);
  padding: 3.3rem;
  margin-bottom: 2rem;
}

.header {
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 1px;
  color:#303030;
  margin-bottom: 1.7rem;
}

.orderstage {
  display: flex;
  font-size: 1.4rem;
  padding: 0 3rem;
  justify-content: space-between;
  color: #000000;
  align-items: center;
  margin-bottom: 2.5rem;
}

.orderstage > div {
  display: flex;
  align-items: center;
}

.verticalline {
  border-left: 2px solid #F4F4F4;
  height: 3.7rem;
  margin: 0 1rem;
}

.stagenumber {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F6FA;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 3rem;
}

.blue {
  background-color: #07004D !important;
  color: #FFF;
}

.button_cta {
  position: relative;
  height: 40px;
}

.btn__green,.btn__light {
  position: absolute;
  top: 0;
  border-radius: 8px;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: bold;
}
.btn__green {
  right: 0;
  background-color:#8CA23B;
  padding: 1.7rem 4rem;
  color: #FFF;
}
.btn__green:focus {
  outline: none;
  box-shadow: 0 0 0 1px #8CA23B;
  transform: scale(1.01);
}

.btn__green:disabled {
  background-color:#a4a797;

}

.btn__light {
  left: 0;
  background-color: #F4F4F4;
  border-radius: 8px;
  padding: 1.7rem 3rem;
  color: #303030;
  font-weight: bold;
}

.btn__light:focus {
  outline: none;
  box-shadow: 0 0 0 1px #8CA23B;
  transform: scale(1.01);
}

.disabled {
  display: none;
}

/* ----------------------------Order Info------------------- */


.orderinfoform p {
  font-size: 1.4rem;
  color: #878DA4;
  margin-bottom: 1.5rem;
}
.orderinfoform label {
  display: block;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 17.71px;
  letter-spacing: 1px;
  margin-bottom: 0.4rem;
}
.split {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.split > div {
  width: 48%;
}

input,select,textarea {
  background-color: #F5F6FA;
  border-radius: 8px;
  padding: 1.6rem;
  border: none;
  width: 100%;
  transition: all .3s ease;
  letter-spacing: 1px;
}

input:focus,select:focus {
  outline: none;
  box-shadow: 0 0 0 1px rgba(21, 156, 228, 0.4);
  transform: scale(1.01);
}

.amount,.email {
  margin-bottom: 2rem;
}

/* --------------------------Message Info ----------------------- */

.meterdetails {
  background-color: #F5F6FA;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.3rem 2rem;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
  position: relative;
}

.meterdetails__left img {
  width: 4rem;
  margin-right: 1.5rem;
}

.meterdetails__left {
  display: flex;
  align-items: center;
  width: 68%;
}

.meteraddress {
  display: flex;
  flex-direction: column;
}

.bold {
  color: #303030;
}

.light {
  font-family: 'CircularStd-Book';
  color: #A9ACC1;
  line-height: 20px;
  font-weight: 100;
}
.meteraddress .light {
  font-size: 1.2rem;
  font-weight: 400;
}
.meteramount {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 30%;
  
}
.commission {
  position: absolute;
  top: -10px;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
}

.charge:hover ~ .commission {
  visibility: visible;
  opacity: 1;
}

.meteramount .light {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.meteramount .totalAmount {
  font-weight: bold;
}

.meteramount {
  cursor: pointer;
}

.message {
  position: relative;
}

.message textarea {
  width: 100%;
  border: none;
  margin-bottom: 2rem;
  height: 10rem;
}

.message textarea:focus {
  outline: none;
  box-shadow: 0 0 0 1px rgba(21, 156, 228, 0.4);
  transform: scale(1.01);
}

.messageLimit {
  display: inline-block;
  padding: .8rem 1.5rem;
  position: absolute;
  bottom: 3.5rem;
  right: 1rem;
  border: 1px solid rgba(21, 156, 228, 0.4);
  border-radius: 5px;
}
/* ----------------------------------Complete Order ------------------------ */
.paymentMethod {  
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.paymentMethod > div {
  background-color: #F5F6FA;
  border-radius: 16px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem;
  cursor: pointer;
}

.paymentMethod > div img {
  height: 4rem;
  margin-right: 1.5rem;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}
.card > div {
  width: 47%;
}
.card > div:last-of-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardExpiry {
  width: 55%;
}
.cardCvv {
  width: 40%;
}

.bank p {
  text-align: center;
  color: #000000;
  margin-bottom: 2rem;
}

.bank a {
  text-decoration: none;
  color: #F7941E;
}

.bankdetails {
  width: 80%;
  margin: 0 auto;
  background-color: #F5F6FA;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.bankdetails > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bankdetails > div:not(:last-of-type) {
  margin-bottom: 1rem;
}

.bankdetails > div span img {
  margin-right: 1rem;
}
.centeralign {
  display: flex;
  align-items: center;
}

/* ------------------------------OrderComplete--------------------- */

.ordercomplete {
  padding: 1.5rem 0;
}

.ordercomplete p {
  text-align: center;
  margin-bottom: 1.5rem;
}
/* -------------------Error Message----------------- */
.errorMessage {
  color:#FF5964;
  background-color: rgba(255, 89, 100, 0.2);
  text-align: center;
  padding: .8rem;
  margin-bottom: 1.5rem;
}

/* -------------------- */
.disableCta {
  display: none;
}

/* ---------------Reciept ---------------*/
.recieptWrapper {
  width: 50%;
  margin: 0 auto;
  background-color: #FFF;
  border-radius: 1.6rem;
  box-shadow: 0px 2px 20px rgba(48, 48, 48, 0.05);
  margin-bottom: 2rem;
}
.orderReciept {
  background-color: #07004D;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 1.6rem;
  border-top-right-radius: 1.6rem;
  padding: 1rem 3rem;
  align-items: center;
  color: #FFF;
}

.grey {
  color: #A3A3A3;
}
.recieptHead {
  display: flex;
  flex-direction: column;
}

.recieptHead > span {
  margin-bottom: 1rem;
}

.recieptHead a {
  text-decoration: none;
}

.recieptDownload {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #F7941E;
}

.recieptDownload img {
  margin-right: 1rem;
}

.recieptDetails {
  padding: 3rem 2.4rem;
}

.recieptDetails > div {
  margin-bottom: 2rem;
}
.recieptDetails p {
  font-size: 2rem;
  font-weight: 700;
  color: #303030;
  margin-bottom: 1.5rem;
}

.recieptSummary {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: flex-end;
  text-transform: capitalize;
}
.recieptSummary_right,
.recieptSummary_left {
  width: 48%;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.recieptSummary_left > div {
  display: flex;
  flex-direction: column;
}
.recieptSummary_left img {
  width: 4rem;
}

.recieptSummary_right div {
  display: flex;
  justify-content: space-between;
}

.recieptSummary_right > div span:not(:first-of-type) {
  font-weight: bold;
  letter-spacing: 1px;
}

.recieptSummary_right div:not(:last-of-type) span,
.recieptSummary_left span:not(:last-of-type),
.recieptSummary_left img {
  margin-bottom: .6rem;
}

.recieptSummary .light {
  font-size: 1.2rem;
  font-family: 'CircularStd-Book';
}
.recieptDetails .book {
  color: #303030;
  font-family: 'CircularStd-Book';
  font-style: normal;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 1.4rem;
  line-height: 18px;
}

.tokenInfo {
  width: 100%;
  font-family: 'CircularStd-Book';
  background-color: #F5F6FA;
  color: #A3A3A3;
  padding: 1.2rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 2rem;
  
}

.tokenInfo span:not(:last-of-type) {
  margin-bottom: 1.2rem;
}

.token {
  color: #8CA23C;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: 'Roboto Mono';
  font-weight: 700;
  letter-spacing: 1.5px;
}

.btn__gift {
  width: 100%;
  font-family: 'Circular Std';
  text-align: center;
  padding: 1.4rem;
  background-color: #8CA23B;
  color: #FFF;
  box-shadow: 0px 5px 10px rgba(112, 126, 158, 0.2);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all .3s ease-in;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.btn__gift:focus {
  outline: none;
  box-shadow: 0 0 0 1px #8CA23B;
  transform: scale(1);
}


.retry {
  display: flex;
  justify-content: center;
}

.retry button {
  color: #FFF;
  background-color: #8CA23B;
  padding: 2rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
}
.giftsent {
  color: #35A7FF;
  text-align: center;
  padding: .8rem;
  margin-bottom: 1.5rem;
  background: rgba(53, 167, 255, 0.2);
}

.mg_btm {
  margin-bottom: 2rem;
}

/*  */
@media screen and (max-width: 1200px) {
  .wrapper,
  .recieptWrapper {
    width: 65%;
  }
  .stagenumber {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper,
  .recieptWrapper {
    width: 70%;
  }
}

@media screen and (max-width: 850px) {
  .wrapper,
  .recieptWrapper {
    width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .wrapper,
  .recieptWrapper {
    width: 90%;
  }
  .stagenumber {
    margin-right: 1.5rem;
  }
}

@media screen and (max-width: 550px) {
  .wrapper,
  .recieptWrapper {
    width: 95%;
  }
}

@media screen and (max-width: 485px) {
  .wrapper,
  .recieptWrapper {
    width: 100%;
  }
  .wrapper {
    padding: 3rem 1rem;
  }
  .split > div {
    width: 49%;
  }
  .orderstage {
    padding: 0 1rem;
  }
  .meterdetails {
    flex-direction: column;
  }
  .meterdetails__left {
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: flex-start;
  }
  .meteramount {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  
}
@media screen and (max-width: 407px) {
  .flex_col{
    flex-direction: column;
  }
  .flex_col > div {
    width: 100%;
  }
  .flex_col > div:not(:last-of-type) {
    margin-bottom: 2rem;
  }
  .card {
    flex-direction: column;
  }
  .card > div {
    width: 100%;
  }
  .cardNumber {
    margin-bottom: 2rem;
  }
  
  .cardExpiry {
    width: 55%;
  }
  .cardCvv {
    width: 40%;
  }
  .orderReciept {
    padding: 1rem 1.6rem;
  }
  .recieptDetails {
    padding: 2rem;
  }
  .recieptSummary {
    flex-direction: column;
  }
  .recieptSummary_left,.recieptSummary_right {
    width: 100%;
  }
  .recieptSummary_left {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }
  .recieptSummary_left img {
    margin-right: 2rem;
    margin-bottom: 0;
  }
  .recieptSummary_right > div {
    margin-bottom: .5rem;
  }
}
@media screen and (max-width: 369px) {
  .orderstage {
    padding: 0;
  }
  .stagenumber {
    margin-right: .3rem;
  }
  .verticalline {
    margin: 0 .3rem;
  }
}
/* 
@media screen and (max-width: 450px) {
  .wrapper {
    padding: 3rem 1.5rem;
  }
  .stagenumber {
    margin-right: 1rem;
  }
  .orderstage {
    padding: 0 1rem;
  }
  .card {
    flex-direction: column;
  }
  .card > div {
    width: 100%;
  }
  .cardNumber {
    margin-bottom: 2rem;
  }
  
  .cardExpiry {
    width: 55%;
  }
  .cardCvv {
    width: 40%;
  }
  .meterdetails {
    padding: 1.3rem 1rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 300px) {
  .stagenumber {
    margin-right: .6rem;
  }
  .orderstage {
    padding: 0rem;
  }
} */