.main {
  padding-top: 2rem;
  max-width: 1440px;
  margin: 0 auto;
}

.main h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #303030;
  text-align: center;
  line-height: 5rem;
  margin-bottom: 3.2rem;
  padding: 0 2rem;
}

.green {
  color: #8CA23C;
}

@media screen and (max-width: 650px) {
  .main h1 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 600px) {
  .main h1 {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}