@font-face {
  font-family: 'Circular Std';
  src: url('./assets/css/CircularStd-Bold.eot');
  src: local('./assets/css/Circular Std Bold'), local('CircularStd-Bold'),
      url('./assets/css/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/css/CircularStd-Bold.woff2') format('woff2'),
      url('./assets/css/CircularStd-Bold.woff') format('woff'),
      url('./assets/css/CircularStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Circular Std';
  src: url('./assets/css/CircularStd-Black.eot');
  src: local('./assets/css/Circular Std Black'), local('./assets/css/CircularStd-Black'),
      url('./assets/css/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/css/CircularStd-Black.woff2') format('woff2'),
      url('./assets/css/CircularStd-Black.woff') format('woff'),
      url('./assets/css/CircularStd-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Circular Std';
  src: url('./assets/css/CircularStd-Medium.eot');
  src: local('./assets/css/Circular Std Medium'), local('./assets/css/CircularStd-Medium'),
      url('./assets/css/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/css/CircularStd-Medium.woff2') format('woff2'),
      url('./assets/css/CircularStd-Medium.woff') format('woff'),
      url('./assets/css/CircularStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Circular Std';
  src: url('./assets/css/CircularStd-Medium.eot');
  src: local('./assets/css/Circular Std Medium'), local('./assets/css/CircularStd-Medium'),
      url('./assets/css/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/css/CircularStd-Medium.woff2') format('woff2'),
      url('./assets/css/CircularStd-Medium.woff') format('woff'),
      url('./assets/css/CircularStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd-Book';
  src: url('./assets/css/CircularStd-Book.eot');
  src: local('./assets/css/Circular Std Book'), local('./assets/css/CircularStd-Book'),
      url('./assets/css/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
      url('./assets/css/CircularStd-Book.woff2') format('woff2'),
      url('./assets/css/CircularStd-Book.woff') format('woff'),
      url('./assets/css/CircularStd-Book.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

*, 
*::before, 
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}
/* @media screen and (max-width: 500px) {
  html {
    font-size: 50%;
  }
} */
body {
  font-family: 'Circular Std','Open Sans',sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  background-color: #E5E5E5;
  font-size: 1.4rem;
  background-image: url('./assets/img/bp-gift.png');
  background-size: cover;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
  font-family: 'CircularStd-Book';
  letter-spacing: 1px;
  font-size: 1.2rem;
}

textarea,input,select {
  font-family: 'Circular Std';
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media screen and (max-width: 500px) {
  body {
    background-image: url('./assets//img/bp-gift-mobile.png');
    background-size: contain;
  }
}