.App {
  width: 80%;
  margin: 0 auto;
  min-height: 100vh; 
}

@media screen and (max-width: 850px) {
  .App {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .App {
    width: 95%;
  }
}

@media screen and (max-width: 540px) {
  .App {
    width: 99%;
  }
}