.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  font-size: 1.4rem;
}

.header {
  padding: 2rem 1.6rem;
}

.logo {
  height: 3.5rem;
  cursor: pointer;
}

.arrow {
  width: 1.6rem;
}

.link {
  text-decoration: none;
  background-color: #8CA23C;
  border-radius: 8px;
  padding: 1.5rem;
  color: #FFF;
}

@media screen and (max-width: 369px) {
  .logo {
    height: 2.7rem;
  }
  .arrow {
    width: 1rem;
  }
  .link {
    padding: 1.2rem 1rem;
    font-size: 1.2rem;
  }
}